import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContentContainer from "../../components/contentContainer"
import CrosshairTile from "../../components/crosshairTile"

class Locations extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const edges = data.locations.edges
    const seoInfo = data.seoInfo.edges[0].node

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={seoInfo.frontmatter.seoTitle || seoInfo.frontmatter.title} description={seoInfo.frontmatter.seoDescription} />
        <div className="content">
        <ContentContainer>
        <div className="columns is-mobile is-multiline">
        {edges.map((edge) => {
              return (
              <div className={`column is-2-desktop is-4-tablet is-6-mobile p-1`} style={{height: "147px", padding: "0"}} key={edge.node.fields.slug}>
                <CrosshairTile>
                  <div style={{width: "100%", height: "100%"}}>
                    <Img style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "contain" }} 
                            fixed={edge.node.frontmatter.image.childImageSharp.fixed} alt=""/>
                  </div>
                </CrosshairTile>
              </div>)
            })}
          </div>
          </ContentContainer>
        </div>
      </Layout>
    )
  }
}

export default Locations

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    locations: allMarkdownRemark (filter: {fileAbsolutePath: {regex: "/partner/(locations/)/"  }}){
      edges {
        node {
          frontmatter {
            date
            title
            image {
              childImageSharp {
                fixed(width: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          html
          fields {
            slug
          }
        }
      }  
    }
    seoInfo: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/partner/locations.md/"}}) {
      edges {
        node {
          frontmatter {
            title
            seoTitle
            seoDescription
          }
        }
      }
    }
  }
`